import { setupAuthentication } from "./auth.js";
import { setupRaiseArticleVisibility } from "./raiseArticleVisibility.js";
import { setupSearchFilters } from "./searchFilters.js";
import { setupMobileMenu } from "./mobileMenu.js";
import { setupFollow } from "./follow.js";
import { setupInfiniteScroll } from "./infiniteScroll.js";
import { setupUpload } from "./upload.js";
import { setupDynamicSearch } from "./dynamicSearch.js";
import { setupSettingsModal } from "./settingModal.js";

document.addEventListener("DOMContentLoaded", function () {
  setupAuthentication();
  setupSearchFilters();
  setupMobileMenu();
  setupRaiseArticleVisibility();
  setupFollow();
  setupDynamicSearch();
  setupInfiniteScroll();
  setupSettingsModal();
  setupUpload();
});
