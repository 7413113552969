export async function setupDynamicSearch() {
  const searchInput = document.getElementById("searchInput");
  const suggestions = document.getElementById("suggestions");
  const searchButton = document.getElementById("searchButton");

  let debounceTimeout;

  // Listen for input events on the search input
  searchInput.addEventListener("input", () => {
    clearTimeout(debounceTimeout); // Clear any previous debounce timer
    debounceTimeout = setTimeout(() => {
      fetchUserSuggestions(searchInput.value);
    }, 300); // Adjust debounce delay as needed (e.g., 300ms)
  });

  // Optional: trigger the search on button click as well
  searchButton.addEventListener("click", () => {
    fetchUserSuggestions(searchInput.value);
  });

  async function fetchUserSuggestions(query) {
    if (!query) {
      suggestions.innerHTML = ""; // Clear suggestions if the input is empty
      return;
    }

    try {
      const response = await fetch(
        `/api/userSearch?query=${query}`
      );
      const searchResult = await response.text();
      displaySuggestions(searchResult);
    } catch (error) {
      console.error("Error fetching user suggestions:", error);
    }
  }

  function displaySuggestions(searchResult) {
    const suggestions = document.getElementById("suggestions");
    suggestions.innerHTML = searchResult; // Clear previous suggestions
  }
}
