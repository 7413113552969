export function setupSettingsModal() {
  const settingsButton = document.getElementById("settings-btn"); // Button to open the settings modal
  const settingsModal = document.getElementById("settingsModal");
  const settingsForm = document.getElementById("settings-form");

  function openSettingsModal() {
    settingsModal.style.display = "flex";
  }

  function closeSettingsModal() {
    settingsModal.style.display = "none";
  }

  // Event listener to open the settings modal when clicking on the settings button
  settingsButton.addEventListener("click", (event) => {
    event.preventDefault();
    openSettingsModal();
  });

  // Event listener to close the modal when clicking outside of it
  window.addEventListener("click", (event) => {
    if (event.target === settingsModal) {
      closeSettingsModal();
    }
  });

  // Submit handler for settings form
  settingsForm.addEventListener("submit", async function (event) {
    event.preventDefault();
    const username = document.getElementById("settings-username").value;
    const email = document.getElementById("settings-email").value; // Corrected typo
    const password = document.getElementById("settings-password").value;
    const repeatPassword = document.getElementById("settings-repeat-password").value;
    
    if (password !== repeatPassword) {
      document.getElementById("settings-message").innerText = "Passwords do not match.";
      return; // Stop the form from submitting
    }
  
    try {
      const response = await fetch("/profile/settings", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, email, password }),
        credentials: "include", // Ensure cookies are sent/received with the request
      });

      const data = await response.json();

      if (data.success) {
        console.log("Settings updated:", data); // Log the entire response object
        document.getElementById("settings-message").innerText = "Settings updated successfully!";
        
        // Optional: Close modal after a short delay on successful update
        setTimeout(closeSettingsModal, 1500);
      } else {
        document.getElementById("settings-message").innerText = `Error: ${data.message}`;
      }
    } catch (error) {
      console.error("Error during settings update:", error);
      document.getElementById("settings-message").innerText = "Something went wrong!";
    }
  });
}
