import {
  getActiveBiasType,
  setActiveBiasType,
  getActivePublicationType,
  setActivePublicationType,
  getActiveSortType, // For Trending/Latest
  setActiveSortType, // For Trending/Latest
  setActiveCategoryType,
  getActiveCategoryType,
  setActiveFeedType,
  getActiveMediaType,
  setActiveMediaType,
  getActiveBumpType,
  setActiveBumpType,
  fetchContentAndCreateHTML,
  updateArticlesContainer,
  toggleVideo,
  removeActiveCardClass,
} from "./searchState.js";

export function setupSearchFilters() {
  const filtersMap = {
    feed: "feed",
    bias: "bias",
    publication: "publication",
    sort: "sort",
    category: "category",
    media: "media",
  };

  const filtersDivIdNames = [
    "bias-filter",
    "publication-filter",
    "sort-filter",
    "category-filter",
    "media-filter",
  ];

  // Apply event listeners to each link within the filter divs
  filtersDivIdNames.forEach((divId) => {
    const filterDiv = document.getElementById(divId);

    if (filterDiv) {
      filterDiv.querySelectorAll("a").forEach((link) => {
        const [value, type] = link.id.split("-"); // Extract value and type from link ID

        if (filtersMap[type]) {
          link.addEventListener("click", (event) => {
            event.preventDefault();

            // Call the merged toggle function with the type, value, and container
            toggleFilters(type, value === "all" ? "" : value, filterDiv);
          });
        }
      });
    }
  });

  // Video event listeners
  const contentContainer = document.querySelector(".contentContainer");
  if (contentContainer) {
    contentContainer.addEventListener("click", function (event) {
      if (event.target.classList.contains("dropdown-link")) {
        toggleVideo(event);
      }
    });
  }

  // Merged toggle function
  async function toggleFilters(type, newValue, filterDiv) {
    const getActiveType = {
      bias: getActiveBiasType,
      publication: getActivePublicationType,
      sort: getActiveSortType,
      category: getActiveCategoryType,
      media: getActiveMediaType,
    }[type];

    const setActiveType = {
      bias: setActiveBiasType,
      publication: setActivePublicationType,
      sort: setActiveSortType,
      category: setActiveCategoryType,
      media: setActiveMediaType,
    }[type];

    // Check if the new value is different from the current active value
    if (newValue !== getActiveType()) {
      setActiveType(newValue); // Update the active type value

      // Update active link classes within the filter div
      filterDiv
        .querySelectorAll("a")
        .forEach((link) => link.classList.remove("active"));
      filterDiv
        .querySelector(`#${newValue || "all"}-${type}-link`)
        .classList.add("active");

      // Fetch new content and update the DOM
      const contentHTML = await fetchContentAndCreateHTML();
      updateArticlesContainer(contentHTML);
    }
  }
  // Setting up right column feeds
  const rightColumnContainer = document.getElementById("rightcolumn");

  rightColumnContainer.addEventListener("click", async (event) => {
    let username = "";
    let userCard;
    const activeCard = rightColumnContainer.querySelector(".activeUserCard");

    if (event.target.classList.contains("followFeedButton")) return; // Prevents switching feeds when following

    removeActiveCardClass();

    // Check if "Show All" is clicked
    if (event.target.closest("#all-feeds-link")) {
      // Show All selected, set username to an empty string
      username = "";
      document.getElementById("all-feeds-link").classList.add("activeUserCard");
    } else {
      // Otherwise, set the active user card based on clicked user
      userCard = event.target.closest(".userCard");

      if (userCard) {
        username = userCard.querySelector(".username").textContent;
        userCard.classList.add("activeUserCard");
      }
    }

    // Set the active feed type and fetch content
    setActiveFeedType(username);
    const contentHTML = await fetchContentAndCreateHTML();
    updateArticlesContainer(contentHTML);
  });

  // Setting up right column feeds
  const centerColumnContainer = document.getElementById(
    "center-column-filters"
  );

  centerColumnContainer.addEventListener("click", async (event) => {
    let username = "";
    let userCard;

    removeActiveCardClass();
    // Check if "Show All" is clicked
    if (event.target.closest("#all-feeds-link")) {
      // Show All selected, set username to an empty string
      username = "";
      document.getElementById("all-feeds-link").classList.add("activeUserCard");
      setActiveBumpType(false);
    } else if (event.target.closest("#bumped-feeds-link")) {
      setActiveBumpType(true);
      userCard = event.target.closest(".yourUserCard");
      document.getElementById("bumped-feeds-link").classList.add("activeUserCard");

    } else {
      // Otherwise, set the active user card to your active user card
      userCard = event.target.closest(".yourUserCard");
      
      if (userCard) {
        username = userCard.id;
        userCard.classList.add("activeUserCard");
      }
      setActiveBumpType(false);
    }

    // Set the active feed type and fetch content
    setActiveFeedType(username);
    const contentHTML = await fetchContentAndCreateHTML();
    updateArticlesContainer(contentHTML);
  });
}
