export async function setupFollow() {
  // THIS IS FOR FOLLOWING AUTHORS WHO WRITE ARTICLES (ex. Journalists)
  document.body.addEventListener("click", async (event) => {
    const followButton = event.target.closest(".followbutton");
    if (!followButton) {
      return; // Prevent further execution if followButton is null
    }

    // Gets the text
    const followText = followButton.parentElement.textContent.trim();


    follow(followButton, followText);
  });

  async function follow(followButton, followText) {
    try {
      // Send a request to the server to follow/unfollow the source
      const response = await fetch(`follows/sources`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Include credentials (like cookies)
        body: JSON.stringify({
          followText: followText, // Send content ID in request body
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to toggle follow status");
      }

      // Assuming server response contains success message and status
      const result = await response.json();
      const isFilled = followButton.classList.contains("clicked");

      // Toggle UI based on the result
      if (isFilled) {
        followButton.src = "/public/img/heart-outline.svg";
        followButton.classList.remove("clicked");
      } else {
        followButton.src = "/public/img/heart-filled.svg";
        followButton.classList.add("clicked");
      }
    } catch (error) {
      console.error("Error toggling follow status:", error);
    }
  }

  // THIS IS FOR FOLLOWING USER'S FEEDS

  const rightColumnContainer = document.getElementById("rightcolumn");

  rightColumnContainer.addEventListener("click", async (event) => {
    const followButton = event.target.closest(".followFeedButton");

    if (!followButton) return; // Exit if the click wasn't on a follow button

    // Find the userCard that contains the follow button
    const userCard = followButton.closest(".userCard");

    if (!userCard) return; // Ensure userCard exists

    // Get the username from the userCard
    const username = userCard.querySelector(".username").textContent;

    if (username) {
      await followFeed(username);
    }
  });

  async function followFeed(username) {
    try {
      const response = await fetch("follows/feeds", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          username: username,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to follow user");
      }

      const result = await response.json();
    } catch (error) {
      console.error("Error following user:", error); // Passed error into the catch block
    }
  }

  const rightColumnButtons = document.querySelectorAll(
    ".rightcolumn .notfollowing, .rightcolumn .following"
  );

  rightColumnButtons.forEach((button) => {
    // Set hover effect
    button.addEventListener("mouseover", () => {
      if (button.classList.contains("notfollowing")) {
        button.textContent = "Follow?";
      } else if (button.classList.contains("following")) {
        button.textContent = "Unfollow?";
      }
    });

    // Reset text when hover ends
    button.addEventListener("mouseout", () => {
      if (button.classList.contains("notfollowing")) {
        button.textContent = "Follow";
      } else if (button.classList.contains("following")) {
        button.textContent = "Following";
      }
    });

    // Set click event to toggle following state
    button.addEventListener("click", () => {
      if (button.classList.contains("notfollowing")) {
        button.classList.remove("notfollowing");
        button.classList.add("following");
        button.textContent = "Following";
      } else if (button.classList.contains("following")) {
        button.classList.remove("following");
        button.classList.add("notfollowing");
        button.textContent = "Not following";
      }
    });
  });
}
