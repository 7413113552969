export function setupUpload(){
  const profilePictureInput = document.getElementById('profilePictureInput');
  const profilePictureLabel = document.getElementById('profilePictureLabel');
  
  // Trigger file input when clicking the profile picture label
  profilePictureLabel.addEventListener('click', () => {
      profilePictureInput.click();
  });
  
  profilePictureInput.addEventListener('change', async function() {
      if (profilePictureInput.files.length > 0) {
          const file = profilePictureInput.files[0];
          const formData = new FormData();
          formData.append('profilePicture', file);
          
          try {
              const response = await fetch('/profile/upload-picture', {
                  method: 'POST',
                  body: formData,
                  credentials: 'include' // This includes credentials like cookies
              });
              
              if (response.ok) {
                  // Assume the server returns the new profile picture URL
                  const data = await response.json();
                  document.getElementById('profilePicture').src = data.profilePicUrl;
              } else {
                  console.error('Failed to upload picture:', response.statusText);
              }
          } catch (error) {
              console.error('Error uploading picture:', error);
          }
      }
  });
}
