import { fetchContentAndCreateHTML, getArticleIds  } from "./searchState.js";

let isFetching = false;

export async function triggerArticleFetch() {
  if (isFetching) return; // Prevent concurrent fetches
  isFetching = true;

  const data = await fetchContentAndCreateHTML(getArticleIds());

  if (typeof data === "string" && data.includes("No feed was found")) {
    console.log("No more content to load");
    isFetching = false;
    return;
  }

  const targetContainer = document.querySelector(".contentContainer");

  if (targetContainer) {
    targetContainer.insertAdjacentHTML("beforeend", data.trim());
  } else {
    console.error("Target container not found.");
  }

  isFetching = false;
}

export function setupInfiniteScroll() {
  const loadMoreTrigger = document.getElementById("load-more");

  const observer = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        console.log("Load more triggered");
        triggerArticleFetch();
      }
    },
    { rootMargin: "0px", threshold: 0.3 }
  );

  if (loadMoreTrigger) {
    observer.observe(loadMoreTrigger);
  } else {
    console.error("Load-more trigger element not found.");
  }

  return {
    triggerArticleFetch,
  };
}
