// upvote.js
export async function setupRaiseArticleVisibility() {
  // Add a click event listener for upvoting
  document.body.addEventListener("click", async (event) => {
    const rateButton = event.target.closest(".ratebutton");
    
    // Only proceed if a rate button was clicked
    if (!rateButton) return;

    const contentID = rateButton.closest(".card")?.getAttribute("id");
    const likeUrl = "/content/like";  // Your server endpoint

    if (!contentID) {
      console.error("Article ID not found.");
      return;
    }

    try {
      // Send the upvote request to the server
      const response = await fetch(likeUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",  // Include credentials (like cookies)
        body: JSON.stringify({
          id: contentID  // Send content ID in request body
        }),
      });

      if (response.ok) {
        const img = rateButton.querySelector(".ratebuttonimg");
        const isFilled = rateButton.classList.contains("clicked");

        // Toggle the button UI based on server response
        if (isFilled) {
          img.src = "/public/img/up-outline.png";
          rateButton.classList.remove("clicked");
        } else {
          img.src = "/public/img/up-filled.png";
          rateButton.classList.add("clicked");
        }
      } else {
        console.error("Failed to register upvote.");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  });

  // Code for bumping and unbumping
  document.body.addEventListener("click", async (event) => {
    const bumpedbutton = event.target.closest(".bumpedbutton");
    
    // Only proceed if a rate button was clicked
    if (!bumpedbutton) return;

    const contentID = bumpedbutton.closest(".card")?.getAttribute("id");
    const bumpUrl = "/content/bump";  // Your server endpoint

    if (!contentID) {
      console.error("Article ID not found.");
      return;
    }

    try {
      // Send the upvote request to the server
      const response = await fetch(bumpUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",  // Include credentials (like cookies)
        body: JSON.stringify({
          id: contentID  // Send content ID in request body
        }),
      });

      if (response.ok) {
        const img = bumpedbutton.querySelector(".bumpedbuttonimg");
        const isFilled = bumpedbutton.classList.contains("clicked");

        // Toggle the button UI based on server response
        if (isFilled) {
          img.src = "/public/img/bumped-outline.svg";
          bumpedbutton.classList.remove("clicked");
        } else {
          img.src = "/public/img/bumped-filled.svg";
          bumpedbutton.classList.add("clicked");
        }
      } else {
        console.error("Failed to register bump.");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  });
}
