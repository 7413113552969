export function setupMobileMenu() {
  const filtersMenuIcon = document.getElementById("filters-menu-icon"); // The button to open the filters menu

  const feedsMenuIcon = document.getElementById("feeds-menu-icon"); // The button to open the feeds menu
  const filters = document.querySelector(".leftcolumn"); // Column with the filters
  const feeds = document.querySelector(".rightcolumn"); // Column with the feeds (recommended/followed)
  const body = document.body; // Reference to the body element

  // Function to close the mobile menu
  const closeMenu = () => {
    filters.classList.remove("open");
    feeds.classList.remove("open");
    body.classList.remove("body-no-scroll");
  };

  // Toggle filters menu on button click
  filtersMenuIcon.addEventListener("click", (event) => {
    filters.classList.toggle("open");
    feeds.classList.remove("open");
    // Prevent background scrolling when the menu is open
    if (filters.classList.contains("open")) {
      body.classList.add("body-no-scroll");
    } else {
      body.classList.remove("body-no-scroll");
    }
  });

  

    // Toggle feeds menu (feeds) on button click
    feedsMenuIcon.addEventListener("click", () => {
      filters.classList.remove("open"); // Close left menu if open
      feeds.classList.toggle("open");
      body.classList.toggle("body-no-scroll", feeds.classList.contains("open"));
    });

  // Close the menu when clicking outside of it
  document.addEventListener("click", (event) => {
    // Check if the menu is open and if the click target is outside the menu and menu icon
    if (
      filters.classList.contains("open") &&
      !filters.contains(event.target) && // Clicked outside the menu
      !filtersMenuIcon.contains(event.target) // Clicked outside the menu button
    ) {
      closeMenu();
    } else if (
      feeds.classList.contains("open") &&
      !feeds.contains(event.target) && // Clicked outside the menu
      !feedsMenuIcon.contains(event.target) // Clicked outside the menu button
    ){
      closeMenu();

    }
  });
}
