// Function to check if the user is logged in (by checking if session_token exists in local storage)
export function setupAuthentication() {
  const signupForm = document.getElementById("signup-form");
  const loginForm = document.getElementById("login-form"); // Added login form

  // login-btn is the ID of the log in button on the navbar
  const loginBtn = document.getElementById("login-btn");
  if (loginBtn.innerText == "Log In") {
    loginBtn.addEventListener("click", (e) => {
      e.preventDefault();
      loginModal.style.display = "flex"; // Open login modal
    });
  } else {
    loginBtn.addEventListener("click", (e) => {
      e.preventDefault();
      logout();
    });
  }

  // Login function
  loginForm.addEventListener("submit", async function (event) {
    event.preventDefault();
    const email = document.getElementById("login-email").value;
    const password = document.getElementById("login-password").value;

    try {
      const response = await fetch("/auth/login", {
        // Send login data to server
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
        credentials: "include", // Ensure cookies are sent/received with the request
      });

      const data = await response.json();

      if (data.success) {
        // Store the token in local storage

        console.log("Login response:", data); // Log the entire response object
        location.reload();
      } else {
        document.getElementById(
          "login-message"
        ).innerText = `Error: ${data.message}`;
      }
    } catch (error) {
      console.error("Error during login:", error);
      document.getElementById("login-message").innerText =
        "Something went wrong!";
    }
  });

  // Logout function
  async function logout() {
    try {
      const response = await fetch("/auth/logout", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      if (data.success) {
        // Clear the session token from local storage

        location.reload(); // Reload the page to reflect the logout state
      } else {
        console.error("Error logging out:", data.message);
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  // Handle signup form submission
  signupForm.addEventListener("submit", async function (event) {
    event.preventDefault();
    const email = document.getElementById("signup-email").value;
    const username = document.getElementById("signup-username").value;
    const password = document.getElementById("signup-password").value;
    const confirmPassword = document.getElementById(
      "signup-confirm-password"
    ).value;

    if (password !== confirmPassword) {
      document.getElementById("message").innerText = "Passwords do not match!";
      return;
    }

    try {
      const response = await fetch("/auth/signup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, username, password }),
      });

      const data = await response.json();

      if (data.success) {
        // If sign-up is successful, reload the page
        window.location.reload();
      } else {
        // If there is an error, display the error message
        document.getElementById("message").innerText = `Error: ${data.message}`;
      }
    } catch (error) {
      console.error("Error during sign-up:", error);
      document.getElementById("message").innerText = "Something went wrong!";
    }
  });

  const loginModal = document.getElementById("loginModal");

  // Hide modal when clicking outside
  window.addEventListener("click", (e) => {
    if (e.target === loginModal) {
      loginModal.style.display = "none";
    }
  });

  // Switch between login and signup forms
  document.getElementById("loginLink").addEventListener("click", () => {
    // Make the login or signup form appear
    document.getElementById("login-form").style.display = "block";
    document.getElementById("signup-form").style.display = "none";

    // Highlight the login or signup link depending on which is active
    document.getElementById("loginLink").classList.add("active");
    document.getElementById("signupLink").classList.remove("active");
  });
  document.getElementById("signupLink").addEventListener("click", () => {
    document.getElementById("login-form").style.display = "none";
    document.getElementById("signup-form").style.display = "block";

    // Highlight the login or signup link depending on which is active
    document.getElementById("loginLink").classList.remove("active");
    document.getElementById("signupLink").classList.add("active");
  });

  // Close the modal when clicking the close button with class .close
  const closeModalBtns = document.querySelectorAll(".close");

  closeModalBtns.forEach((button) => {
    button.addEventListener("click", () => {
      // Find the closest parent modal and hide it
      const modal = button.closest(".modal");
      if (modal) {
        modal.style.display = "none";
      }
    });
  });
}
