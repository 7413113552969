// This file irritates me greatly. Infinite Scroll and Search Filters are heavily interlinked BUT TECHNICALLY are different areas of code. Keeping them separate is the right choice from an long-term updating perspective. However, both need to keep track of what the current search is. Thus this horrible file exists to give them what they need. There is a simplification opportunity using a Javascript object instead of have two variables but honestly, this works for now.

// To avoid an error, I need to check if usernameElement exists before trying to get its textContent.
const usernameElement = document.getElementById("username");
let activeFeedType = usernameElement ? usernameElement.textContent : "";

// Sets default feed to the username
let activeBiasType = ""; // Default Search
let activePublicationType = ""; // Default Publication
let activeSortType = "trending"; // Default Sort
let activeCategoryType = "";
let activeMediaType = "";
let activeBumpType = false;

// infiniteScroll.js and searchFilters.js share a lot of these functions and variables, hence the searchState file.
export function getActiveFeedType() {
  return activeFeedType;
}

export function setActiveFeedType(newType) {
  activeFeedType = newType;
}

export function getActiveBiasType() {
  return activeBiasType;
}

export function setActiveBiasType(newType) {
  activeBiasType = newType;
}

export function getActivePublicationType() {
  return activePublicationType;
}

export function setActivePublicationType(newType) {
  activePublicationType = newType;
}

export function getActiveSortType() {
  return activeSortType;
}

export function setActiveSortType(newType) {
  activeSortType = newType;
}

export function setActiveCategoryType(newCategory) {
  activeCategoryType = newCategory.toLowerCase();
}

export function getActiveCategoryType() {
  return activeCategoryType;
}

export function getActiveMediaType() {
  return activeMediaType;
}

export function setActiveMediaType(newType) {
  activeMediaType = newType;
}

export function getActiveBumpType() {
  return activeBumpType;
}

export function setActiveBumpType(newType) {
  activeBumpType = newType;
}

export function getArticleIds() {
  // Get content from the contentContainer
  const contentContainer = document.querySelector(".contentContainer");
  const loadedArticles = contentContainer
    ? Array.from(contentContainer.querySelectorAll(".card"))
    : [];

  const loadedContentIds = loadedArticles
    .map((content) => content.id)
    .join(",");

  return loadedContentIds;
}

export async function fetchContentAndCreateHTML(loadedContentIds = []) {
  try {
    const params = new URLSearchParams({
      feedType: getActiveFeedType(),
      biasType: getActiveBiasType(),
      publicationType: getActivePublicationType(),
      sortType: getActiveSortType(),
      mediaType: getActiveMediaType(),
      category: getActiveCategoryType(),
      bumpType: getActiveBumpType(),
      loadedContentIds: loadedContentIds,
    });

    // Fetch the HTML directly from the server
    const response = await fetch(
      `/content/fetchContentAndCreateHTML?${params.toString()}`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch content with filters`);
    }

    // Parse the response as text (since it's HTML)
    return response.text();

    // Update the DOM with new content
  } catch (error) {
    console.error("Error fetching content:", error);
  }
}

export function updateArticlesContainer(contentHTML) {
  let contentContainer = document.getElementById(`contentContainer`);

  // Ensure the container exists
  if (!contentContainer) {
    console.error(`No element with id 'contentContainer'.`);
    return;
  }

  // Clear the existing content and append the new HTML
  contentContainer.innerHTML = contentHTML;
  // Add in toggling to each video link
}

export function toggleVideo(event) {
  event.preventDefault();
  const videoContainer = event.target.nextElementSibling;
  if (videoContainer && videoContainer.classList.contains("content-video-container")) {
    const isHidden = window.getComputedStyle(videoContainer).display === "none";
    videoContainer.style.display = isHidden ? "block" : "none";
  }
}

export function removeActiveCardClass() {

  const centerColumnContainer = document.getElementById("center-column-filters");

  const activeCenterCard = centerColumnContainer.querySelector(".activeUserCard");

  // Remove active class from currently active card
  if (activeCenterCard) activeCenterCard.classList.remove("activeUserCard");

  const rightContainer = document.getElementById("rightcolumn");

  const activeRightCard = rightContainer.querySelector(".activeUserCard");

  // Remove active class from currently active card
  if (activeRightCard) activeRightCard.classList.remove("activeUserCard");

}